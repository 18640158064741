<script setup lang="ts">
import type { SliceInterface } from '@voix/types'
import { resolveFieldsArray } from '@voix/composables/useField'

const props = defineProps<{
  inView: boolean
  slice: SliceInterface
}>()

defineSlice({
  name: { label: 'Bento Container', group: 'Bento', layouts: ['VoixMarketing'] },
  slots: [
    { name: 'default', allowedElements: ['SlicesBentoGenericCard'] },
    { name: 'background', allowedElements: [
      'SlicesBackgroundsPattern',
      'SlicesBackgroundsGlow',
    ] },
  ],
  description: '',
  preview: 'SlicesBentoContainer.jpg',
  fields: {

  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const defaultElements: SliceInterface[] = props.slice.elements?.filter(ele => ele.slot === 'default') as SliceInterface[]
function getSpan(slice: SliceInterface) {
  const fields = resolveFieldsArray(slice)

  if (!fields)
    return 1

  return fields.colspan?.value || 1
}
</script>

<template>
  <div class="relative py-8 px-8">
    <div class="absolute inset-0 z-0 pointer-events-none">
      <slot name="background" />
    </div>
    <div class="relative z-10 container mx-auto">
      <div class="relative xl:grid xl:grid-cols-12">
        <div class="xl:col-span-10 xl:col-start-2 lg:grid lg:grid-cols-12 lg:gap-8 w-full">
          <div v-for="s in defaultElements" :key="s.id" class="p-1 rounded" :class="[`span-${getSpan(s)}`]">
            <VoixSlice :slice="s" :slice-index="s.id" class="w-full h-full" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.span-1 {
  @apply lg:col-span-1
}
.span-2 {
  @apply lg:col-span-2
}
.span-3 {
  @apply lg:col-span-3
}
.span-4 {
  @apply lg:col-span-4
}
.span-5 {
  @apply lg:col-span-5
}
.span-6 {
  @apply lg:col-span-6
}
.span-7 {
  @apply lg:col-span-7
}
.span-8 {
  @apply lg:col-span-8
}
.span-9 {
  @apply lg:col-span-9
}
.span-10 {
  @apply lg:col-span-10
}
.span-11 {
  @apply lg:col-span-11
}
.span-12 {
  @apply lg:col-span-12
}
</style>
